$sacramento: #0A5063;
$ocean: #007291;
$light-blue: #DFF3F9;
$tangerine: #ff9803;
$surf: #16A0C1;
$emerald: #00DAA8;
$coal: #4F4F4F;
$moss: #5B9F6C;
$dijon: #B97D35;
$carmine: #B04645;
$amethyst: #9387E0;
$white: #ffffff;
$black: #000000;
$dark-grey: #767676;
$grey: #7E7E7E;
$light-grey: #BDBDBD;
$dirty-white: #F0F0F0;
$dirty-white-shadow: #D0D0D0;
$mandarin: #D9840B;
$purple: #8e5b9a;
$pink: #d6578b;
$dusk: #4a5783;
$border-radius: 0.25rem;
$border-width: 1px;
$black-5: rgba(0, 0, 0, 0.05);
$pale-grey: #f5f5f6;


// Default background
$body-bg: $white;

// Theme Colors
$theme-colors: (
  fonts: $coal,
  primary: #009DA0,
  success: #0CC27E,
  info: #1CBCD8,
  warning: #FF8D60,
  danger: #FF586B,
  secondary: #868e96,
  light: #f8f9fa,
  dark: #343a40,
);

@mixin linear-gradient {
  background-image: linear-gradient(101deg, $tangerine, $pink 31%, $purple 63%, $dusk 96%);
}
@mixin dh-shadow-radius-3 {
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 10px 4px $black-5;
  background-color: $white;
}
@mixin dh-shadow-radius-4 {
  @include dh-shadow-radius-3;
  border-radius: 3px;
}
@mixin dh-font-family {
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $coal;
}
@mixin Text-Style {
  font-family: Roboto, serif;
  font-size: 24px;
  font-weight: bold;
  color: $coal;
}

@mixin Text-Style-3 {
  font-family: Roboto, serif;
  font-size: 18px;
  font-weight: bold;
  color: $coal;
}

@mixin Text-Style-2 {
  font-family: Roboto, serif;
  font-size: 18px;
  color: $light-grey;
}
