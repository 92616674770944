
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/roboto-v27-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/roboto-v27-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/roboto-v27-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/roboto-v27-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/roboto-v27-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/roboto-v27-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/roboto-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/roboto-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/roboto-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/roboto-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/roboto-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/roboto-v27-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/roboto-v27-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/roboto-v27-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/roboto-v27-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/roboto-v27-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/roboto-v27-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/roboto-v27-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/iconfont/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url('./assets/iconfont/MaterialIcons-Regular.woff2') format('woff2'),
       url('./assets/iconfont/MaterialIcons-Regular.woff') format('woff'),
       url('./assets/iconfont/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

//$orange: #ff9803;
//$mat-pink: $orange;

$dh-primary-palatte: (
  50: #fff3e1,
  100: #ffe0b3,
  200: #ffcc81,
  300: #ffb74f,
  400: #ffa729,
  500: #ff9803,
  600: #ff9003,
  700: #ff8502,
  800: #ff7b02,
  900: #ff6a01,
  A100: #ffffff,
  A200: #fff7f2,
  A400: #ffd6bf,
  A700: #ffc5a6,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dh-frontend-primary: mat-palette($dh-primary-palatte);
$dh-frontend-accent: mat-palette($mat-orange, A200, A100, A400);

// The warn palette is optional (defaults to red).
$dh-frontend-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$dh-frontend-theme: mat-light-theme($dh-frontend-primary, $dh-frontend-accent, $dh-frontend-warn);


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($dh-frontend-theme);

.mat-slide-toggle-bar {
  background-color: #ddd;
}
.treatment-item span.mat-slide-toggle-content {
  color: #333;
}

/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.css';
@import 'styles/styles';

/* Angular Material */
html, body {
  height: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

