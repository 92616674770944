.treatment-item {
  .mat-slide-toggle {
    display: block;
    height: auto;
    white-space: normal;
    width: 100%;

    span.mat-slide-toggle-content {
      word-wrap: break-word !important;
      white-space: normal;
    }
  }
}
