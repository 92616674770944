@import "variables";
@import "forms";

/*
Fix modals
*/
.md-datepicker-calendar-pane,
.cdk-overlay-container {
  z-index: 1200 !important;
}

.not-mvp {
  display: none;
}

body {
  @include dh-font-family;
}

/*** Style to show the icon inside the text field ***/
.input-icons {
  width: 100%;
  i {
    position: absolute;
    padding: 10px;
    min-width: 40px;
    opacity: 0.6;
  }
  .dh-input-field {
    width: 100%;
    padding-left: 2em;
  }
}
/*** Style to show the icon inside the text field ***/

.dh-input-field {
  &::placeholder {
    opacity: 0.6;
  }
  &::-ms-input-placeholder,
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 0.6;
  }
}

/* Navigation */
.mainmenu.navbar {
  background-color: $white;
  box-shadow: 0 2px 10px 4px $black-5;
  margin-bottom: 20px;

  ul li {
    flex: 1;

    .nav-link {
      @include dh-font-family;
      color: $grey;
      font-weight: bold;
    }
  }
}

footer {
  padding-bottom: 10px;
  color: $grey;

  button.btn.dh-transparant {
    color: $grey;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 2px;
  }
}

.orange-text {
  color: #fc8c14;
}
/* Health Assesment  -  Progress Bar */
.health-progress {
  margin-bottom: 32px;

  span {
    float: right;
    font-weight: bold;
  }

  .progress {
    height: 8px;
    margin-top: 8px;
  }
}

// health-assessment
.feature-block {
  .treatment-plan .card-header {
    display: flex;

    .action {
      margin-left: auto;
    }
  }

  .card-header {
    background-color: transparent;
    border-bottom: 0;
  }
}

.modal-dialog {
  max-width: 600px;
}

.treatment-block {
  .mat-form-field-flex {
    border: 1px solid $light-grey;
    border-radius: 5px;
    padding: 0 0 10px 10px;
    .mat-form-field-infix {
      padding: 0;
    }
  }
  .mat-form-field-appearance-legacy {
    .mat-form-field-underline {
      height: 0;
    }
  }
  .mat-form-field.mat-focused {
    .mat-form-field-ripple {
      background: none;
    }
  }

  .card-text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $coal;
    padding-bottom: 15px;
  }

  .table {
    thead th {
      font-family: Roboto;
      font-size: 10px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey;
      text-transform: uppercase;
    }

    tbody {
      .name {
        color: $tangerine;
      }
    }
  }

  .diseases-physicians {
    label.form-check-label {
      margin-top: 4px;
    }
  }
}

.dh-recommender {
  @include linear-gradient;
  @include dh-shadow-radius-3;
  padding: 18px 20px;

  .recommender-block {
    .card-text {
      padding-bottom: 10px;
      min-height: 50px;
    }

    .card-body {
      padding: 20px;

      .card-text {
        padding-bottom: 18px;
      }

      .date {
        @include dh-font-family;
        color: $light-grey;
      }

      .information-block {
        background-color: $white;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 2px 10px 4px $black-5;
      }

      .dh-icon {
        border: 0;
        height: 14px;
        margin: 8px 10px;
        padding: 0;
        width: 14px;
      }
    }
  }
}

.carepath {
  .top {
    color: $light-grey;
    font-size: 12px;
    padding-bottom: 0;
  }
  .timeline {
    padding-top: 20px;
  }
}

i.ce-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  object-fit: contain;
  width: 48px;
  display: inline-block;
  margin-top: -8px;
}

i.ce {
  background-image: url("/assets/images/ce/ce.png");
  background-size: 50px 50px;
}

i.ce-md {
  background-image: url("/assets/images/ce/md.png");
  background-size: 50px 50px;
}

i.ce-udi {
  background-image: url("/assets/images/ce/udi.png");
  background-size: 50px 50px;
}

i.ce-ref {
  background-image: url("/assets/images/ce/ref.png");
  background-size: 50px 50px;
}

i.ce-instructions {
  background-image: url("/assets/images/ce/info.png");
  background-size: 50px 50px;
}

i.ce-date-of-manufacture {
  background-image: url("/assets/images/ce/manufacturing-date.png");
  background-size: 50px 50px;
}

i.ce-sn {
  background-image: url("/assets/images/ce/sn.png");
  background-size: 50px 50px;
}

i.ce-manufacturer {
  background-image: url("/assets/images/ce/manufacturer.png");
  background-size: 50px 50px;
}

.btn {
  @include dh-font-family;
}

////////////////////////////////////////////////////////////////////////////////////////////

// TODO: Need to CLEAN THIS UP
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1240px;
  }
}

.row {
  margin: 0;
}

.main-content {
  margin-top: 20px;

  .card {
    margin-bottom: 20px;
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 2px 10px 4px $black-5;
    background-color: $white;
  }
}

.card-title {
  height: 21px;
  @include Text-Style-3;
}

.btn {
  @include dh-font-family;
  font-weight: bold;
  color: $white;

  &.btn-warning {
    border-radius: 18px;
  }

  &.btn-success {
    border-radius: 18px;
  }

  &.dh-large {
    height: 30px;
    border-radius: 18px;
    border-radius: 4px;
    font-weight: normal;
    height: 32px;
    width: 80px;
  }

  &.dh-transparant {
    height: 30px;
    border-radius: 18px;
    background-color: transparent;
    color: $tangerine;
  }

  &.dh-login {
    background-color: #fa9804;
    border-color: #fa9804;
    border-radius: 0.3rem;
    &:active:focus {
      background-color: #fa9804;
      border-color: #fa9804;
    }
  }
}

/* PATIENT OVERVIEW DETAILS */

.patient-overview {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 20px;
  }

  .sync-health-action {
    margin-left: auto;
    order: 2;
  }

  .patient-img {
    width: 100px;
    height: 100px;
    border-radius: 69px;
    background: url(/assets/images/mock/user-profile-dummy.png) no-repeat center
      center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .patient-medical-information ul {
    display: flex;
    flex-direction: row;
    list-style: none outside none;
    padding-inline-start: 0;

    li {
      margin-right: 30px;
    }

    span {
      display: block;
      color: $grey;

      &.primary-head {
        font-weight: bold;
      }
    }
  }

  .patient-information {
    .patient-name {
      @include Text-Style;
      padding-bottom: 12px;
    }
  }
}
.patient-id {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  span {
    line-height: 30px;
    cursor: pointer;
    margin-left: 5px;
  }
}

.button-link {
  color: $tangerine;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: $tangerine;
  }
  &.cancel {
    color: $grey;
  }
}
.mat-dialog-container {
  .mat-dialog-content {
    border-bottom: 1px solid $dirty-white;
    border-top: 1px solid $dirty-white;
  }
  .mat-dialog-actions {
    padding: 1.15em 0;
  }
}

.mat-menu-content {
  button {
    .activity-icon {
      margin-right: 0.5em;
      width: 1.5em;
    }
  }
}

.bb svg {
  width: 100%;
}

.bb-xgrid,
.bb-ygrid {
  stroke-dasharray: none;
  color: #b0b0b0;
  opacity: 0.2 !important;
}

.bb-axis .tick line {
  display: none;
}

.bb-axis .tick text {
  font: 30;
}

.bb-axis .tick text {
  font-size: 10px;
  opacity: 0.5;
}

.bb-axis-y .tick:first-of-type {
  display: none;
}

.bb-axis-y path,
.bb-axis-x path {
  stroke: #b0b0b0;
}

.bb-line {
  stroke-width: 2px;
}

.legend {
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.legend span {
  text-align: center;
  cursor: pointer;
  color: white;
  padding: 10px;
}

.toggled-legend {
  color: gray !important;
  opacity: 0.3;
}

.tab-panel {
  height: 55px;
  align-items: center;
  box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.05);

  .fa {
    margin-right: 5px;
  }

  button {
    outline: none;
    box-shadow: none;
  }

  .mat-button-toggle-focus-overlay {
    border-bottom: none;
  }

  .mat-button-toggle {
    border-top: 0px;
    width: 200px;
    font-size: 15px;
    color: $dark-grey;
    height: inherit;
    display: flex;
    align-items: center;
  }

  .mat-button-toggle-checked {
    border-top: 4px solid #e59800;
    background-color: white;
    color: #e59800;

    .mat-button-toggle-focus-overlay {
      border-bottom: none;
      background-color: #e59800;
    }

    .mat-button-toggle {
      padding-top: 0px;
      border-top: 0px;
      height: inherit;
    }
  }
}

.gray-1 {
  color: #8a8a8a;
}

.activate-addon {
  .mat-dialog-title {
    margin: 0px;
  }

  .tree-container {
    padding-top: 1rem;
  }

  .addon-card {
    border-top: rgb(0, 172, 0) 0.4rem solid;
  }
}

.patient-overview-table {
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding: 3px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.notification-overlay.mat-menu-panel {
  max-width: fit-content;
}

.custom-filter-wrapper {
  .mat-sort-header-content {
    flex-direction: column;
    align-items: flex-start;
  }
  .mat-form-field {
    display: block;
  }
  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      left: -5px;
    }
    .mat-form-field-outline-start {
      border: none;
    }
    .mat-form-field-outline-gap {
      border-top: none;
    }
    .mat-form-field-outline-end {
      border-top: none;
      border-right: none;
      border-radius: 0;
    }
    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }
    .mat-form-field-infix {
      padding-bottom: 0;
    }
    .mat-form-field-flex {
      padding-left: 0;
    }
  }
}

.custom-filter-dd {
  &.mat-select-panel {
    overflow: visible;
    max-height: fit-content;
  }
}

/********** Material stepper Style override ************/
.mat-horizontal-stepper-header-container {
  .mat-step-icon {
    color: white;
  }
  .mat-step-header {
    &:hover {
      background-color: white;
    }
  }
}

/********** Material SnackBar *****************/
.mat-snack-bar-container {
  color: white;
  &.error {
    background-color: $carmine;
  }
  &.success {
    background-color: $moss;
  }
  button {
    color: white;
  }
}

/********** Material Menu ********************/
.mat-menu-content {
  button {
    &.mat-menu-item {
      outline: none;
    }
  }
}


/************* Material Tooltip ****************/

.mat-tooltip {
  white-space: pre-line;
}

/*********** Material Button group ****************/
@at-root .mat-button-toggle-appearance-standard {
  .mat-button-toggle-label-content {
    line-height: 25px;
  }
}
.dh-btn-toggle {
  .mat-button-toggle-checked {
    background-color: $surf;
    &.mat-button-toggle-appearance-standard {
      color: white;
    }
  }
}

/************ Material Expansion Panel  **********/

.treatment-plan {
  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }
  .mat-expansion-panel-header {
    font-weight: bold; 
    .mat-expansion-indicator::after {
      color: $tangerine;;
    }
  }
}

/************* IE 11 Hacks ***************************/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mat-form-field-flex {
    align-items: flex-start !important;
  }
  .treatment-block {
    .mat-form-field {
      display: inline !important;
    } 
  }
}



.dh-btn {
  @include dh-font-family;
  line-height: 2.6em;
  &.dh-btn-primary {
    background: $tangerine;
    color: $white;
    min-width: 80px;
    outline: none;
    border: none;
  }
  &.dh-btn-secondary {
    border: 1px solid $tangerine;
    color: $tangerine;
    min-width: 80px;
  }
  &.rounded-btn {
    border-radius: 99em;
  }
  &:disabled {
    opacity: 0.5;
  }
}